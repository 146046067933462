<template>
    <div class="card" style="padding-bottom: 1rem; margin-bottom: 1rem;">
        <div class="card-header">
            <CardHeaderComponent :title="'Headlines'" :categories="categories" :thePlayers="thePlayers"></CardHeaderComponent>
        </div>
        <div class="card-block headline-card" v-for="headline in headlines">
            <headline-helper>
                <img slot="thumbnail" class="headline-thumbnail img-fluid"
                     v-bind:src="'/storage/'+headline.thumbnail_image">
                <template slot="title"><a class="text-dark" :href="getNewsLink(headline.id)">{{headline.title}}</a></template>
                <h6 slot="subtitle" class="card-subtitle mb-2 text-muted">By: {{headline.author}}</h6>
                <a slot="link1" :href="getNewsLink(headline.id)" class="card-link">Read More</a>
            </headline-helper>
        </div>
    </div>
</template>

<script>
    import CardHeaderComponent from "./CardHeaderComponent";
    export default {
        data() {
            return {
                csrfToken: window.Laravel.csrfToken
            }
        },
        components: {
            CardHeaderComponent
        },
        props: ['headlines', 'thePlayers', 'categories'],
        mounted() {
            console.log('Headline List mounted.')
        },
        methods: {
            getNewsLink(headlineId) {
                if (this.thePlayers) {
                    return '/the-players/news/' + headlineId;
                }
                return '/news/' + headlineId

            }
        }
    }
</script>