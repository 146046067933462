<template>
    <div class="container">
        <div class="row main-panel">
            <div class="col-md-8">
                <div class="padding-bottom">
                    <div class="card">
                        <headline-slider :modules="news.modules"></headline-slider>
                    </div>
                </div>
                <div class="row">
                    <div v-if="!vuex.getters.auth" class="col-sm padding-bottom">
                        <a href="https://www.phpa.com/site/alumni">
                            <img src="/images/phpa_alum_logo.jpg" style="width: 100%;"/>
                        </a>
                    </div>
                    <div v-else class="col-sm padding-bottom">
                        <a href="/membership/financial-planning">
                            <img src="/images/layout/RaymondJamesGraphic.jpg" style="width: 100%; max-height: 200px;"/>
                        </a>
                    </div>
                    <div v-if="!vuex.getters.auth" class="col-sm padding-bottom">
                        <a href="/store">
                            <img src="/images/layout/shop.jpg" style="width: 100%;max-height: 200px;"/>
                        </a>
                    </div>
                    <div v-else class="col-sm padding-bottom">
                        <a href="/membership">
                            <img src="/images/layout/PartnerProgramGraphic.jpg" style="width: 100%;max-height: 200px;"/>
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <headline-list :headlines="news.headlines" :categories="categories"></headline-list>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row sidebar-card">
                    <div class="col-md">
                      <div class="card" style="height: 100px;">
                        <div class="card-header"><strong>Socials</strong></div>

                        <div class="socials-logos" style="display: flex; flex-wrap: nowrap; justify-content: space-evenly; align-items: center; height:150px;">

                          <a href="https://www.instagram.com/thephpa/">
                            <img src="/images/layout/Instagram.png" style="max-height: 50px;">
                          </a>
                          <a href="https://twitter.com/thephpa">
                            <img src="/images/layout/x.png" style="max-height: 50px;">
                          </a>


                        </div>
                      </div>
                    </div>
                </div>
                <template v-if="vuex.getters.auth">
                    <div class="row sidebar-card">
                        <div class="col-md">
                            <div class="card">
                                <div class="card-header"><strong>Call Redline</strong></div>
                                <a href="http://callredline.com/">
                                    <img src="/images/partners/sliderTimeToTalk.jpg"
                                         style="width: 100%;"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </template> <template v-if="vuex.getters.auth">
                    <div class="row sidebar-card">
                        <div class="col-md">
                            <div class="card">
                                <div class="card-header"><strong>Alumni Assistance Program</strong></div>
                                <a href="https://www.phpa.com/site/alumni">
                                    <img src="/images/layout/AlumniAssistanceProgram.jpg"
                                         style="width: 100%;"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="row sidebar-card">
                    <div class="col-md">
                        <div class="card" style="padding-bottom: 1rem;">
                            <div class="card-header"><strong>Strategic Partners</strong></div>
                            <partner-section></partner-section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                vuex: vuex,
            }
        },
        props: ['news', 'categories'],
        mounted() {
            console.log('Index Component mounted.');
        }
    }
</script>