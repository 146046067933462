<template>
    <div id="carousel" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li v-for="(module, index) in modules" data-target="#carousel" :data-slide-to="index"
                :class="'pointer '+(index == 0 ? ' active' : '')"><span class="slide-title pointer"
                                                                      v-if="module.short_title">{{module.short_title}}</span>
            </li>
        </ol>
        <div class="carousel-inner" role="listbox">
            <div v-for="(module, index) in modules" :class="'carousel-item' + (index == 0 ? ' active' : '')">
                <img style="width: 100%" class="d-block img-fluid"
                     :src="'/storage/'+module.module_image"
                     :alt="module.short_title">
                <div class="carousel-caption d-none d-flex bg-carousel-text">
                    <p class="d-md-block">
                        <span v-html="module.summary"></span>
                        <br/>
                        <a :href="getLink(module.id)">Read More</a>
                    </p>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</template>

<script>
    export default {
        props: ['modules', 'thePlayers'],
        mounted() {
            console.log('Headline Slider mounted.')
        },
        methods: {
            getLink(moduleId) {
                if (this.thePlayers) {
                    return '/the-players/news/'+moduleId;
                }
                return '/news/'+moduleId;
            }
        }
    }
</script>
