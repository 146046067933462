<template>
    <div class="card" style="padding: 0.5rem;">
        <div class="row">
            <div class="col-sm-2">
                <slot name="thumbnail"></slot>
            </div>
            <div class="col-sm-10">
                <div class="card-block">
                    <h5 class="card-title">
                        <slot name="title"></slot>
                    </h5>
                    <slot name="subtitle"></slot>
                    <p class="card-text">
                        <slot name="content"></slot>
                    </p>
                    <slot name="link1"></slot>
                    <slot name="link2"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Headline Helper Slot mounted.')
        }
    }
</script>