<template>
    <div v-bind:id="'carousel' + number" class="carousel slide" data-ride="carousel" v-bind:data-interval="interval">
        <div class="carousel-inner" role="listbox" style="height:175px;">
            <div v-for="(slide, index) in slides" :class="'carousel-item' + (index == 0 ? ' active' : '')"
                 style="position:relative; width: 100%; height: 175px;">
                <a :href="slide.link" target="_blank">
                    <img class="d-block img-fluid partner-image" :src="slide.image_path">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                interval: this.getRandomInterval()
            }
        },
        props : ['slides', 'number'],   //number is used to distinguish this slide from others in the parent
        mounted() {
            console.log('Partner Slider ' + this.number + ' mounted.');
            let carousel_slide_finished = 'slide.bs.carousel';
            $('#carousel'+this.number).on(carousel_slide_finished, () => {
                this.interval = this.getRandomInterval();
            })
        },
        methods: {
            getRandomInterval() {
                let x = Math.random().toFixed(1);
                if (x <= 0.33) return 3000;
                else if (x > 0.33 && x < 0.66) return 4000;
                else return 5000;
            }
        }
    }
</script>
