<template>
    <div class="d-flex flex-wrap justify-content-start justify-content-md-between align-items-center">
        <h5 class="mb-2">{{title}}</h5>
        <div class="d-flex flex-wrap">
            <form class="form-inline mb-2 mr-2" method="get" :action="getAction()">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search News" name="query">
                    <div class="input-group-append">
                        <button class="btn btn-outline-dark" type="submit">Search</button>
                    </div>
                </div>
            </form>
            <form class="form-inline mb-2" method="get" :action="getAction(true)">
                <div class="input-group">
                    <select class="form-control pretty-select" name="category">
                        <option value="all">Pick a Category</option>
                        <option v-for="category in categories" :value="category.id">{{category.name}}</option>
                    </select>
                    <div class="input-group-append">
                        <button class="btn btn-outline-success" type="submit">Go</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        props: ['categories', 'thePlayers', 'title'],
        mounted() {
            console.log('Headline Helper Slot mounted.')
        },
        methods: {
            getAction: function (categories = false) {
                if (categories) {
                    if (this.thePlayers) {
                        return '/the-players/news/category'
                    }
                    return '/news/category'
                }
                if (this.thePlayers) {
                    return '/the-players/news/search'
                }
                return '/news/search'
            },
        }
    }
</script>